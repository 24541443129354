import React from 'react';
import { Box, Typography, Grid, Button, Modal } from '@mui/material';
import key2background from '../../../assets/fondocandado2.png';

export const ComoFuncionaSection = () => {
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <Box
            sx={{
                textAlign: 'center',
                py: 6, // Reduced padding
                backgroundImage: `url(${key2background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                zIndex: 1, // Add zIndex to ensure content is above the background
            }}
        >
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Grid item xs={10} md={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Flex direction column
                            justifyContent: 'center', // Center content vertically
                            backgroundColor: 'primary.main', // Background color
                            color: 'white', // Text color
                            borderRadius: '20px', // Rounded corners
                            padding: '10px 20px', // Padding
                            textAlign: 'center', // Center text
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Typography variant="h5" align='center' sx={{ mb: 2, mt: 2 }}>
                            Diseña promociones personalizadas, agrega clientes y sigue sus interacciones en tiempo real.
                        </Typography>
                        <Typography variant="h5" align='center' sx={{ mb: 2 }}>
                            Mejora la retención y satisfacción de tus clientes.
                        </Typography>
                    </Box>
                </Grid>

                {/* Video Modal */}
                <Grid item xs={10}>
                    <Button variant="contained" color="primary" size='large' onClick={handleOpenModal} sx={{ borderRadius: '20px', mt: 5, minWidth: '50%' }}>
                        Ver video
                    </Button>
                    <Modal open={openModal} onClose={handleCloseModal}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                            <iframe
                                //width="600"
                                height="600"
                                src="https://www.youtube.com/embed/T-J1zOz8kso"
                                title="Fidelidapp Demo Video"
                                allowFullScreen
                            ></iframe>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        </Box>
    );
};
