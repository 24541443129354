import React from 'react';
import { Box,Container, Typography, Grid, Card, CardContent, Icon } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';

const FeaturesSection = () => {
  // Define an array of features with title, description, and icon
  const features = [
    {
      title: 'Asesoría en Gestión de Fidelización',
      description: 'Nuestro equipo trabaja contigo para diseñar estrategias de fidelización adaptadas a tus objetivos comerciales, que te ayudarán a aumentar la frecuencia de visitas, el ticket promedio, o la retención a largo plazo',
        icon: <LocalOfferIcon fontSize="large" color='secondary' />,
    },
    {
      title: 'Fidelidapp – La Plataforma',
      description: 'Nuestra herramienta intuitiva te permite gestionar promociones, recompensas y programas de lealtad de manera eficiente, Fidelidapp facilita cada paso, permitiéndote enfocarte en lo que mejor haces: ofrecer un excelente servicio.',
      icon: <PeopleIcon fontSize="large" color='secondary'/>,
    },
    {
      title: 'Análisis y Segmentación de Clientes',
      description: 'Análizamos y fortalecemos tu base de clientes a través de una segmentación de clientes. Con Fidelidapp, obtienes la información necesaria para tomar las mejores decisiones de fidelización. ',
      icon: <BarChartIcon fontSize="large" color='secondary' />,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ textAlign: 'center', py: 12 }}>
      <Typography variant="h3" gutterBottom color={'primary'} sx={{ mb: 6 }}>
       Nuestros Servicios
      </Typography>
      <Grid container spacing={1} justifyContent="center" alignItems="stretch">
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card elevation={6} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography variant="h5" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturesSection;
