import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  TextField,
  CircularProgress,
  IconButton,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import api from '../../utils/api'; // Make sure the path to your api utility is correct
import PromotionStats from './components/PromotionStats';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PromotionDetails = () => {
  const { pid } = useParams(); // Get the promotionid parameter from the URL
  const [promotion, setPromotion] = useState(null); // State variable to store promotion data
  const [loading, setLoading] = useState(true); // State variable to track loading state
  const [email, setEmail] = useState(''); // State variable to store the client email
  const [isAdmin, setIsAdmin] = useState(false); // State variable to track if the user is an admin
  const [processing, setProcessing] = useState(false); // State variable to track processing state
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);


  // New state to handle edit mode and form values
  const [isEditing, setIsEditing] = useState(false);
  const [editValues, setEditValues] = useState({
    title: '',
    description: '',
    visitsRequired: '',
    promotionDuration: '',
    imageUrl: '',
    image: null,
    promotionRecurrent: '',
    promotionType: '',
  });

  useEffect(() => {
    // Fetch promotion data based on promotionid
    const fetchPromotionDetails = async () => {
      try {
        const [promotionResponse] = await Promise.all([
          api.get(`/api/promotions/${pid}`),
        ]);

        setPromotion(promotionResponse.data); // Update promotion state with fetched data
        if (promotionResponse.data.statistics) {
          setIsAdmin(true);
        }

        setEditValues({
          title: promotionResponse.data.title,
          description: promotionResponse.data.description,
          visitsRequired: promotionResponse.data.visitsRequired,
          promotionDuration: promotionResponse.data.promotionDuration,
          imageUrl: promotionResponse.data.imageUrl, // Initialize image URL
          promotionRecurrent: promotionResponse.data.promotionRecurrent,
          promotionType: promotionResponse.data.promotionType,
          conditions: promotionResponse.data.conditions,
        });

        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching promotion details:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchPromotionDetails(); // Call the function to fetch promotion details
  }, [pid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true); // Disable button and show processing message
    try {
      const client = await api.post('/api/promotions/client', { clientEmail: email, promotionId: pid });
      //Set toast duration 10 segconds
      toast.success('Cliente agregado correctamente. Tu Fidelicard será enviada a tu correo. Recuerda validar tu visita al pagar la cuenta!', { autoClose: 10000 });
      setProcessing(false);
    } catch (error) {
      toast.error('Error al agregar el cliente');
      setProcessing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enable edit mode
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const handleSaveEdit = async () => {
    console.log(editValues);
    try {
      await api.put(`/api/promotions/${pid}`, {
        title: editValues.title,
        description: editValues.description,
        visitsRequired: editValues.visitsRequired,
        promotionDuration: editValues.promotionDuration,
        promotionRecurrent: editValues.promotionRecurrent,
        promotionType: editValues.promotionType,
        conditions: editValues.conditions,
        image: editValues.image, // Include updated image 
      }, { headers: { 'Content-Type': 'multipart/form-data' } });
      toast.success('Promoción actualizada correctamente, se refrescará la página en 3 segundos.');
      setPromotion({ ...promotion, ...editValues }); // Update the local state with new values
      setIsEditing(false); // Exit edit mode
      setTimeout(() => {
        window.location.reload();
      }, 2 * 1000);
    } catch (error) {
      console.error('Error updating promotion:', error);
      toast.error('Error al actualizar la promoción');
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) { // 5MB in bytes
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'La imagen no puede ser mayor a 5MB.'
      }));
    } else {
      setSelectedImageUrl(URL.createObjectURL(file));
      setEditValues((prevFormData) => ({
        ...prevFormData,
        image: file
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: ''
      }));
    }
  };

  if (loading) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          border: 0,
          padding: 5,
        }}
      >
        <CircularProgress />
        <Typography sx={{ marginTop: 2 }}>Cargando detalles de la promoción...</Typography>
      </Container>
    );
  }

  if (!promotion) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          border: 0,
          padding: 5,
        }}
      >
        <Typography sx={{ marginTop: 2 }}>No se encontró la promoción.</Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        minHeight: '66vh',
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={1} md={1} sx={{ textAlign: 'center' }}>
          {isAdmin && (
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={11} md={11} sx={{ textAlign: 'left' }}>
          <Typography variant="h5">{promotion.title}</Typography>
        </Grid>

        <Grid item xs={11} md={11} sx={{ textAlign: 'left' }}>
          <Typography variant="h6">Para ser agregado a la promoción, inscribe tu email a continuación:</Typography>
        </Grid>

        <Grid item xs={12} md={8} sx={{ textAlign: 'center' }}>
          <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
            <TextField
              label="Email Cliente"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: 2, width: '80%' }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={processing}
              sx={{
                width: '80%',
                borderRadius: '10px',
                mb: 4,
              }}
            >
              {processing ? 'Procesando...' : 'Sumar a la Promoción!'}
            </Button>
          </form>
        </Grid>

        <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
          <Typography variant="h6">Pueds revisar los detalles de la promoción:</Typography>
          <br />
          <Typography component="p" dangerouslySetInnerHTML={{ __html: promotion.description.replace(/\r\n|\r|\n/g, '<br />') }} />
          <br />
          <Typography component="p">La promoción se activa con: {promotion.visitsRequired} visitas en {promotion.promotionDuration} días</Typography>
          <br />
          <Typography component="p">Términos y condiciones aplican, serán enviados a tu correo una ves inscrito a la promoción</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <img src={promotion.imageUrl} alt="Promotion" style={{ width: '90%', height: 'auto', marginTop: '10px' }} />
        </Grid> 

       

        {isAdmin && (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px', marginBottom: '20px', maxWidth: '100%' }}
          >
            <Divider width="100%" sx={{ marginTop: '10px', marginBottom: '20px' }} />
            <PromotionStats statistics={promotion.statistics} pid={pid} />
          </Box>
        )}
      </Grid>

      {/* Edit Mode Dialog */}
      <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogTitle>Editar Promoción</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label="Título"
            value={editValues.title}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            name="description"
            label="Descripción"
            value={editValues.description}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          {/* <TextField
            name="visitsRequired"
            label="Visitas Requeridas"
            value={editValues.visitsRequired}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            name="promotionDuration"
            label="Duración de la Promoción (días)"
            value={editValues.promotionDuration}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
          /> */}
          <TextField
            name="conditions"
            label="Condiciones"
            value={editValues.conditions}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />

          <Typography variant="subtitle1">Sube una imagen de la promoción</Typography>
          <label htmlFor="upload-image-input">
            <Button
              component="span"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              color='secondary'
            >
              Subir imagen
            </Button>
          </label>
          <VisuallyHiddenInput
            id="upload-image-input"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          {errors.image && <Typography color="error">{errors.image}</Typography>}
          {selectedImageUrl && (
            <div>
              <img src={selectedImageUrl} alt="Selected" style={{ width: '50%', height: 'auto' }} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PromotionDetails;
