import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Typography, CircularProgress, Paper, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { toast } from 'react-toastify';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import Lottie from 'react-lottie';
import celebrationAnimation from '../../assets/celebration.json'; // Add your celebration animation JSON file here
import api from '../../utils/api';
import keyUrl from '../../assets/fondodellave2.png';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Helmet } from 'react-helmet-async';



const ClientPromotionVisit = () => {
  const { cid, pid } = useParams(); // Get the promotionid parameter from the URL
  // const navigate = useNavigate();

  const [promotion, setPromotion] = useState(null); // State variable to store promotion data for that client
  const [promotionDetails, setPromotionDetails] = useState(null); // State variable to store promotion general details
  const [imageUrl, setImageUrl] = useState(null); // State variable to store promotion data for that client
  const [client, setClient] = useState(null); // State variable to store promotion data
  const [user, setUser] = useState(null); // State variable to store promotion data
  const [loading, setLoading] = useState(true); // State variable to track loading state
  const [processing, setProcessing] = useState(false); // State variable to track processing state
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [showScanner, setShowScanner] = useState(false);



  const generateIcons = (actualVisits, visitsRequired) => {
    const icons = [];
    for (let i = 0; i < actualVisits; i++) {
      icons.push(<Favorite key={`active-${i}`} sx={{ color: 'green' }} />);
    }
    for (let i = actualVisits; i < visitsRequired; i++) {
      icons.push(<FavoriteBorder key={`active-${i}`} sx={{ color: 'gray' }} />);
    }
    return icons;
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const handleScan = async (result) => {
    setProcessing(true); // Disable button and show processing message
    if (result) {
      try {
        const dailyKey = await result[0].rawValue;
        const response = await api.post('/api/promotions/visit', { clientEmail: client.email, promotionId: pid, dailyKey });
        toast.success('Visita Registrada con éxito, la página se refrescará en 3 segundos');
        setTimeout(() => {
          window.location.href = window.location.href;  // Recarga la página sin lanzar una operación abortada
        }, 2 * 1000);
      }
      catch (error) {
        toast.error('Error al validar la visita!');
      }
    } else {
      toast.error('No se pudo leer el codigo QR');
    }
    setProcessing(false); // Enable button and hide processing message
    setShowScanner(false); // Close the scanner dialog after scanning
  }


  useEffect(() => {
    // Fetch promotion data based on promotionid
    const fetchPromotionDetails = async () => {
      setLoading(true);

      try {
        const user = await api.get('/auth/current');
        setUser(user.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }

      try {
        const response = await api.get(`/api/promotions/${cid}/${pid}`); // Adjust the API endpoint as per your backend
        console.log(response.data);
        setPromotion(response.data.promotion); // Update promotion state with fetched data
        setPromotionDetails(response.data.promotionDetails); // Update promotionDetails state with fetched data
        setClient(response.data.client); // Update client state with fetched data
        setImageUrl(response.data.promotionDetails.imageUrl); // Update imageUrl state with fetched data

        if (response.data.promotion.status === 'Redeemed') {
          setShowPopup(true);
        }

        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching promotion details:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchPromotionDetails(); // Call the function to fetch promotion details
  }, [cid, pid]); // Run the effect whenever promotionid changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true); // Disable button and show processing message
    try {
      const response = await api.post('/api/promotions/visit', { clientEmail: client.email, promotionId: pid });
      toast.success('Visita Registrada con éxito, la página se refrescará en 3 segundos');
      //Refresh page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 2 * 1000);
    } catch (error) {
      toast.error(error.response.data.error)
    }
  };

  const closePopup = () => {
    const confirmClose = window.confirm('Esto cerrara el pop up, podrasara verlo nuevamente, recarga la página. ¿Continuar?');
    if (!confirmClose) return;
    setShowPopup(false);
  };

  const restartPromotion = async () => {
    //Set alert to confirm
    const confirmRedeem = window.confirm('Un encargado de la tienda debe validar el canje, ¿Estás seguro de que deseas continuar?');
    if (!confirmRedeem) return;
    try {
      const responste = await api.post('/api/promotions/restart', { clientEmail: client.email, promotionId: pid });
      toast.success('Promoción Canjeada');

      //Refresh page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3 * 1000);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: celebrationAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (loading) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          border: 0,
          padding: 5,
        }}
      >
        <CircularProgress />
        <Typography sx={{ marginTop: 2 }}>Cargando detalles de la promoción...</Typography>
      </Container>
    );
  }

  if (!promotion) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          border: 0,
          padding: 5,
        }}
      >
        <Typography sx={{ marginTop: 2 }}>No se encontró la promoción.</Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{promotionDetails.title || 'Fidelidapp'}</title>
        <meta name="description" content={promotionDetails.description || 'Detalles de la promoción'} />
        <meta property="og:title" content={promotionDetails.title || 'Fidelidapp'} />
        <meta property="og:description" content={promotionDetails.description || 'Detalles de la promoción'} />
        <meta property="og:url" content={`https://www.fidelidapp.cl/promotions/${pid}`} />
      </Helmet>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 5,
          minHeight: '66vh',
        }}
      >

        <Grid container spacing={2} justifyContent="center" alignItems="center" >
          {/* Tituo Pagina */}
          <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5" sx={{ mt: 4 }} >
              {promotionDetails.title}
            </Typography>
          </Grid>

          {/* Virtual Card */}
          <Paper
            elevation={3}
            sx={{
              position: 'relative',
              p: 2,
              mt: 4,
              borderRadius: '10px',
              overflow: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${keyUrl})`,
                backgroundSize: 'cover',
                opacity: 0.33, // Adjust the opacity value here
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>FideliCard</Typography>
              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Visitas: {generateIcons(promotion.actualVisits, promotionDetails.visitsRequired)}</Typography>
              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Estado: {promotion.status}</Typography>
              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Canjes Realizados: {promotion.redeemCount}</Typography>
              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1">Email del Cliente: {client.email}</Typography>
              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1">Fecha de Registro: {formatDate(promotion.addedDate)}</Typography>
              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1">Fin de Vigencia: {formatDate(promotion.endDate)}</Typography>
              </Grid>

              {promotion.lastRedeemDate &&
                <Grid item xs={12} md={4} sx={{ textAlign: 'center', mt: 2 }}>
                  <Typography variant="body1">Fecha Último Canje: {formatDate(promotion.lastRedeemDate)}</Typography>
                </Grid>
              }

            </Grid>
          </Paper>


          {/* Botón Escanear QR */}
          <Button
            variant="contained"
            onClick={() => setShowScanner(true)} // Open the QR scanner dialog
            sx={{
              width: '80%',
              borderRadius: '10px',
              mt: 4,
            }}
          >
            Abrir Escáner QR para sumar una visita
          </Button>



          {/* Detalles de Promocion */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Typography variant="h6">Detalles de la Promoción</Typography>
            <br />
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: promotionDetails.description.replace(/\r\n|\r|\n/g, '<br />') }} />
            <Typography variant="body1">Tipo: ({promotionDetails.promotionType})</Typography>
          </Grid>

          {/* Imagen de Promocin */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <img src={imageUrl} alt="Promotion" style={{ width: '90%', height: 'auto', marginTop: '10px' }} />
          </Grid> 




          {/* QR Scanner Dialog */}
          <Dialog open={showScanner} onClose={() => setShowScanner(false)}>
            {processing ? <p>Procesando...</p> :
              <>
                <DialogTitle>Escáner de Código QR</DialogTitle>
                <DialogContent>
                  <Scanner
                    onScan={(result) => handleScan(result)}
                    style={{ width: '100%' }}
                  />
                </DialogContent>
              </>
            }
          </Dialog>


          {/* Botón Sumar Visita
        <Grid item xs={10} sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              variant="contained"
              disabled={processing}
              sx={{
                width: '80%',
                borderRadius: '10px',
              }}
            >
              {processing ? 'Procesando...' : 'Sumar Visita'}
            </Button>
          </form>
        </Grid>*/}
        </Grid>

        {/* Popup for Redeemed Promotion */}
        <Dialog open={showPopup} onClose={closePopup}>
          <DialogTitle>¡Felicidades!</DialogTitle>
          <DialogContent>
            <Lottie options={defaultOptions} height={200} width={200} />
            <Typography variant="body1">Haz logrado las {promotionDetails.visitsRequired} visitas exitosamente de: </Typography>
            <Typography variant="body1" sx={{ fontStyle: 'italic' }} > {promotionDetails.title} </Typography>
            <br />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} >Para canjear tu recompensa, debes mostrar este pop up al encargado de la tienda! </Typography>
            <br />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} >¿Canjear la promoción?</Typography>

            <Button onClick={restartPromotion} variant="contained" sx={{ mt: 2, mr: 2 }}>Si</Button>
            <Button onClick={closePopup} variant="contained" sx={{ mt: 2, mr: 2 }}>No</Button>


            {/* {promotionDetails.promotionRecurrent == 'True' && (
              <>
                <Typography variant="body1" >Esta promoción ha sido marcada domo recurrente, ¿deseas volver a iniciarla?</Typography>
                <Button onClick={restartPromotion} variant="contained" sx={{ mt: 2, mr: 2 }}>Si</Button>
              </>
            )}
            <Button onClick={closePopup} variant="outlined" sx={{ mt: 2 }}>Cerrar</Button> */}
          </DialogContent>
        </Dialog>

      </Container>
    </>
  );
};

export default ClientPromotionVisit;
