import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';

const UseCasesSection = () => {
  const useCases = [
    {
      title: 'Salones de Uñas',
      description: 'Optimiza la gestión de citas y fideliza a tus clientes con promociones personalizadas.',
      details: {
        problema: 'Los salones de uñas enfrentan frecuentemente la falta de citas recurrentes y la fidelización de clientes.',
        promocion: 'Se creó una promoción donde los clientes que reserven cuatro citas consecutivas obtienen un 20% de descuento en la siguiente cita.',
        obsequio: 'A los clientes que completan la promoción se les regaló un set de uñas decorativas.',
        resultados: 'El salón experimentó un aumento del 35% en la retención de clientes y un incremento del 20% en las reservas de citas consecutivas.',
      },
      image: 'https://images.pexels.com/photos/7755238/pexels-photo-7755238.jpeg',
    },
    {
      title: 'Restaurantes',
      description: 'Incrementa las reservas y mejora la experiencia del cliente con promociones y reservas online.',
      details: {
        problema: 'Los restaurantes suelen tener dificultades para llenar mesas durante los días de semana.',
        promocion: 'Se implementó una promoción de "Martes de Descuento", ofreciendo un 15% de descuento en la factura total para reservas realizadas en línea.',
        obsequio: 'Se ofreció una bebida de cortesía a los clientes que participaron en la promoción.',
        resultados: 'El restaurante vio un aumento del 40% en las reservas de los martes y un incremento del 25% en la satisfacción del cliente.',
      },
      image: 'https://images.pexels.com/photos/262978/pexels-photo-262978.jpeg?auto=compress&cs=tinysrgb&w=800',
    },
    {
      title: 'Peluquerías',
      description: 'Facilita la gestión de horarios y servicios, y ofrece descuentos a clientes habituales.',
      details: {
        problema: 'Las peluquerías a menudo luchan por llenar sus horarios durante las horas no pico.',
        promocion: 'Se lanzó una promoción de "Horario Feliz" donde los clientes que reservan servicios entre las 2 y las 4 de la tarde obtienen un 10% de descuento.',
        obsequio: 'Los clientes recibieron un producto de cuidado capilar de regalo al participar en la promoción.',
        resultados: 'La peluquería logró aumentar la ocupación de horas no pico en un 30% y mejorar la fidelidad del cliente en un 15%.',
      },
      image: 'https://images.pexels.com/photos/1319461/pexels-photo-1319461.jpeg',
    },
    {
      title: 'Estudios de Tatuajes',
      description: 'Aumenta las reservas y fideliza a tus clientes ofreciendo promociones y descuentos exclusivos.',
      details: {
        problema: 'Los estudios de tatuajes a menudo enfrentan la fluctuación en la cantidad de reservas y la lealtad de los clientes.',
        promocion: 'Se lanzó una promoción de "Tatuaje de Referencia" donde los clientes que refieran a un amigo obtienen un 15% de descuento en su siguiente tatuaje.',
        obsequio: 'A los clientes que refirieron amigos se les regaló una sesión de retocado gratuito para sus tatuajes.',
        resultados: 'El estudio de tatuajes experimentó un aumento del 25% en nuevas reservas y una mejora del 30% en la retención de clientes.',
      },
      image: 'https://images.pexels.com/photos/4125659/pexels-photo-4125659.jpeg',
    },
  ];

  const [selectedCase, setSelectedCase] = useState('Salones de Uñas',);

  const handleCaseClick = (caseName) => {
    setSelectedCase(caseName === selectedCase ? null : caseName);
  };

  const renderContent = () => {
    if (!selectedCase) {
      return <Typography variant="body1" gutterBottom sx={{ height: '100%' }}>Elige un caso de éxito para ver detalles.</Typography>;
    }

    const selectedUseCase = useCases.find((useCase) => useCase.title === selectedCase);

    return (
      <Box sx={{ textAlign: 'left', padding: 2 }}>
        <Typography variant="h4" color={'primary'} sx={{ mb: 2 }}>
          {selectedUseCase.title}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.description}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.problema}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.promocion}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.obsequio}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.resultados}
        </Typography>
        <img src={selectedUseCase.image} alt={selectedUseCase.title} style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', marginTop: 16 }} />
      </Box>
    );
  };

  return (
    <Container sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h3" gutterBottom color="primary" sx={{ mb: 6 }}>
        Casos de éxito
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          borderRadius: 8,
          padding: 3,
          border: '1px solid black',
        }}
      >
        <Grid item xs={12} md={3}>
          {useCases.map((useCase) => (
            <Card
              key={useCase.title}
              sx={{
                cursor: 'pointer',
                mb: 2,
                backgroundColor: selectedCase === useCase.title ? '#e0e0e0' : 'white',
                borderLeft: selectedCase === useCase.title ? '4px solid #3E7CB1' : 'none',
                textAlign: 'center', // Center align the text
              }}
              onClick={() => handleCaseClick(useCase.title)}
            >
              <CardContent>
                <Typography variant="h6">
                  {useCase.title}
                </Typography>
                <Button onClick={() => handleCaseClick(useCase.title)} color="primary">
                  Ver más
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
        <Grid item xs={12} md={9}>
          <Card
            sx={{
              mb: 1,
              backgroundColor: 'white',
            }}
          >
            {renderContent()}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UseCasesSection;
