import React, { useState } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import api from '../../../utils/api';
import { toast } from 'react-toastify';

const UserActions = ({ accountId, navigate, PromotionEnabled }) => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddUser = async () => {
        try {
            await api.post(`/accounts/add/${accountId}`, { email });
            handleClose();
            toast.success(`${email} ha sido agregado correctamente`);
        } catch (error) {
            console.error('Error adding user to account:', error.response.data.error);
            toast.error(error.response.data.error);
        }
    };

    const handleLogout = async () => {
        try {
            await api.get('/auth/logout');
            toast.success("Usuario deslogueado correctamente");
            navigate('/');
        } catch (error) {
            console.error('Error logging out user:', error);
            toast.error("No se ha podido cerrar sesión");
        }
    };

    return (
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
                <Button variant="contained" disabled={!PromotionEnabled} onClick={() => navigate('/create')}>
                    Crear Nuevo Programa de Fidelización
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={() => navigate('/CreateAgenda')}>
                    Crear Nueva Agenda
                </Button>
            </Grid>
            {/* <Grid item>
                <Button variant="outlined" onClick={() => alert('Funcionalidad en desarrollo!!')}>
                    Enviar Mail a tus Clientes
                </Button>
            </Grid> */}
            

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Agregar Usuario a la Cuenta</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email del Usuario"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancelar</Button>
                    <Button onClick={handleAddUser} color="primary">Agregar</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default UserActions;
