import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

export const LandingSection = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl" sx={{ textAlign: 'left', pt: 8, pb: 12, minHeight: '75vh' }}>
      <Typography variant="h2" gutterBottom fontWeight={700} color="primary" sx={{
        mt: {
          xs: 0, // No margin-top for extra-small screens
          md: 15 // Margin-top for medium screens and up
        }
      }}>
        Haz que tus clientes vuelvan
      </Typography>
      <Typography variant="h5" color="textSecondary" paragraph>
        Crea fácilmente programas de fidelidad para tus clientes, ajustados a la realidad de tu empresa.
      </Typography>
      <Button
        onClick={() => navigate('/signup')}
        variant="contained"
        color="primary"
        size="large"
        sx={{ borderRadius: '20px', mt: 5, display: 'block', maxWidth: '250px' }}
      >
        Crea una Cuenta Gratis
      </Button>
      <ScrollLink to="ComoFuncionaSection" smooth={true} duration={500} sx={{ borderRadius: '20px', mt: 2, display: 'block' }}>
        <Button
          to="/como-funciona"
          variant="outlined"
          color="primary"
          size="large"
          sx={{ borderRadius: '20px', mt: 2, display: 'block', maxWidth: '250px' }}
        >
          Descubre cómo
        </Button>
      </ScrollLink>
    </Container>
  );
};
