import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import api from '../../utils/api';

function EmailSender() {
  const [csvFile, setCsvFile] = useState(null);
  const [subject, setSubject] = useState('Asunto del Email'); // Default subject
  const [template, setTemplate] = useState('Hola [Name],\n\nEste es tu detalle: [Detail].');
  const [imageFile, setImageFile] = useState(null); // New state for image file

  // Handle file input change
  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  // Handle image input change
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  // Handle subject input change
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  // Handle template input change
  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  // Send emails function
  const handleSendEmails = async () => {
    if (!csvFile) {
      alert('Please upload a CSV file first.');
      return;
    }

    //Alert confirmation
    const confirm = window.confirm('Estas seguro que deseas enviar los emails?');
    if (!confirm) {
      return;
    }

    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('subject', subject); // Add subject to form data
    formData.append('template', template);

    if (imageFile) {
      formData.append('image', imageFile); // Attach the image to the form data
    }

    try {
      const response = await api.post('/api/email', formData);

      if (response.status === 200) {
        alert('Emails sent successfully!');
      } else {
        alert('Failed to send emails.');
      }
    } catch (error) {
      alert('Error sending emails: ' + error);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">
        A continuación podrás crear emails y enviarlos masivamente.
      </Typography>
      <br />
      <Typography variant="body2">
        Usa [Name] y [Detail] para personalizar el mensaje.
      </Typography>
      <br />
      <br />

      <input type="file" accept=".csv" onChange={handleFileChange} />
      <br />


      <TextField
        label="Email Subject"
        value={subject}
        onChange={handleSubjectChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Email Template"
        multiline
        rows={8}
        value={template}
        onChange={handleTemplateChange}
        fullWidth
        margin="normal"
      />

      <br />
      <Typography variant="body2">Upload an image to include in the email:</Typography>
      <input type="file" accept="image/*" onChange={handleImageChange} /> {/* Image file input */}
      <br />

      <Button variant="contained" color="primary" onClick={handleSendEmails} sx={{ mt: 2 }}>
        Send Emails
      </Button>
    </Box>
  );
}

export default EmailSender;
