import React, { useState } from 'react';
import { Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import  api from '../../../utils/api'; // Importar la función API
import backgroundImage from '../../../assets/Cover.jpeg';


export const LandingSection = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '' });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const redirectToAgenda = () => {
    navigate('/agendas/66e22754c6702cd9fdb7f114');
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    //Validate that formData has data
    if (!formData.name || !formData.email) {
      alert('Por favor completa todos los campos');
      return;
    }
    
    try {
      await api.post('/auth/contact', formData); // Llamada a la API para enviar el formulario
      alert('Solicitud enviada con éxito');
      handleClose();
    } catch (error) {
      console.error('Error al enviar la solicitud', error);
      alert('Hubo un error al enviar la solicitud');
    }
  };

  return (
    <Container   maxWidth={false}
    sx={{ 
      textAlign: 'left', pt: 8, pb: 12, 
      width: '100%',
      backgroundImage: `url(${backgroundImage})`, // Usando la imagen importada
      backgroundSize: 'cover', // Para cubrir todo el contenedor
      backgroundPosition: 'center', // Centrar la imagen
      backgroundRepeat: 'no-repeat', // Evitar que se repita la imagen
      backgroundAttachment: 'fixed', // Para que la imagen no se mueva
      
    }}>
      <Typography variant="h2" gutterBottom fontWeight={700} color="white" sx={{
        mt: {
          xs: 0, // No margin-top for extra-small screens
          md: 15 // Margin-top for medium screens and up
        }
      }}>
        Transforma la Lealtad <br/> de tus Clientes
      </Typography>
      <Typography variant="h5" color="white" paragraph>
        Descubre cómo potenciar la fidelización en tu negocio, aumentando la retención y la satisfacción de tus clientes.
      </Typography>
      <Button
        onClick={redirectToAgenda}
        variant="contained"
        color="secondary"
        size="large"
        fontStyle="bold"
        sx={{ borderRadius: '20px', mt: 5, display: 'block', minWidth: '350px' }}
      >
        Agenda tu Asesoría Gratuita
      </Button>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        size="large"
        fontStyle="bold"
        sx={{ borderRadius: '20px', mt: 5, display: 'block', minWidth: '350px' }}
      >
        Escríbenos para conocer más
      </Button>
      <Typography variant="body1" color="white" gutterBottom sx={{ mt: 2, fontStyle: 'italic' }}>
        Sin compromiso. Conoce cómo podemos ayudarte a fidelizar a tus clientes.
      </Typography>

      {/* Pop-up con el formulario */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Déjanos tus datos para que nos contactemos contigo.</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nombre"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Correo Electrónico"
            name="email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Mensaje"
            name="message"
            type="text"
            fullWidth
            value={formData.message}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
