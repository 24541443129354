import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AvailableSlotsTable from './components/AvailableSlotsTable';
import ExistingAppointments from './components/ExistingAppointments.js'; // Asegúrate de importar el componente
import api from '../../utils/api';

const fetchAvailableSlots = async (agendaId) => {
  try {
    const response = await api.get(`/api/agenda/getAvailableSlots/${agendaId}`);
    return { availableSlotsByDay: response.data.availableSlotsByDay, name: response.data.name, description: response.data.description };
  } catch (error) {
    console.error('Error al obtener las horas disponibles:', error);
    return {};
  }
};

const Agenda = () => {
  const { agendaId } = useParams();
  const [availableSlotsByDay, setAvailableSlotsByDay] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const getAvailableSlots = async () => {
      const { availableSlotsByDay, name, description } = await fetchAvailableSlots(agendaId);
      setName(name);
      setDescription(description);
      setAvailableSlotsByDay(availableSlotsByDay);
    };

    getAvailableSlots();
  }, [agendaId]);

  return (
    <div>
      <AvailableSlotsTable availableSlotsByDay={availableSlotsByDay} name={name} agendaId={agendaId} description={description} />
      <ExistingAppointments agendaId={agendaId} />
    </div>
  );
};

export default Agenda;
