import React from 'react';
import { Container, Grid, Card, CardContent, CardActions, Button, Typography, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            '1 promoción activa',
            '50 clientes activos ',
            'Hasta 3 administradores',
            'Reportes generales y por promoción'
        ],
        buttonText: 'Comienza Gratis',
        buttonVariant: 'contained',
    },
    {
        title: 'Pro ',
        price: '9USD',
        description: [
            '10 promociones activas',
            'Clientes activos ilimitados',
            'Hasta 5 administradores',
            'Reportes generales y por promoción',
            'Envía emails a tus clientes (En desarrollo)',
            'Personalización de emails y plataforma (En desarrollo)',
            'Soporte prioritario',
        ],
        buttonText: 'Pronto',
        buttonVariant: 'outlined',
    },
    {
        title: 'Premium',
        price: '49USD',
        description: [
            'Promociones Ilimitadas',
            'Clientes activos ilimitados',
            'Hasta 5 administradores',
            'Reportes generales y por promoción',
            'Envía emails a tus clientes (En desarrollo)',
            'Encuestas de satisfacción y nps (En desarrollo)',
            'Análisis avanzado de datos (En desarrollo)',
            'Personalización de emails y plataforma (En desarrollo)',
            'Account Mangaer dedicado',
        ],
        buttonText: 'Pronto',
        buttonVariant: 'outlined',
    },
];

export const Pricing = () => {
    return (
        <Container sx={{}}>
            <Typography variant="h3" align='center' color={'primary'} sx={{ mb: 6 }}>
                Planes
            </Typography>
            <Grid container spacing={1} alignItems="flex" >
                {tiers.map((tier) => (
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'Pro (soon)' ? 12 : 12}
                        md={4}
                        sx={{ mt: 5 }}
                    >
                        <Card>
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2 }}>
                                    <Typography component="h2" variant="h5" color="text.primary">
                                        {tier.title}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2 }}>
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${tier.price}
                                    </Typography>
                                    <Typography variant="h6" color="black">
                                        /mes
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2, width: '100%' }}>
                                    <ul style={{ padding: 0, margin: 0, width: '100%' }}>
                                        {tier.description.map((line) => (
                                            <Typography variant="subtitle1" align="left" key={line} >
                                                <FavoriteIcon sx={{ color: 'primary.main', mr: 1, mb: -1 }} /> {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={tier.buttonVariant} color="primary" onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}>
                                    {tier.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};
