import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const MetricsCard = ({ title, value, limit }) => (
  <Card sm={12} md={3}>
    <CardContent>
      <Typography variant="h6" color={'primary'}>{title}</Typography>
      <Typography variant="h4">{value} {limit && `/ ${limit}`}</Typography>
    </CardContent>
  </Card>
);

export default MetricsCard;
