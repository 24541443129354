import React, { useState, useEffect } from 'react';
import { Button, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import PadlockIcon from '../../assets/LogoAzulSinFondo.png';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';

const SignIn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await api.get('/auth/current');
        navigate('/home');
      } catch (error) {
        
      }
    };
    fetchCurrentUser();
  }, [navigate]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    api.post('/auth/signin', formData)
      .then((response) => {
        toast.success("Usuario loggeado correctamente, serás redireccionado al home.");
        setTimeout(() => {
          navigate('/home');
        }, 2000);
      })
      .catch((error) => {
        console.error('Error signing in:', error);
        toast.error("No se ha podido inciar sesión");
      });
  };

  const errorMessage = (error) => {
    console.log(error);
    toast.error("No se ha podido iniciar sesión");
  };

  const handleGoogleSignIn = (response) => {

    const userData = {
      googleIdToken: response.credential,
    };

    api.post('/auth/google-signin', userData)
      .then((response) => {
        toast.success("Usuario logueado correctamente");
        navigate('/home');
      })
      .catch((error) => {
        console.error('Error signing in with Google:', error);
        toast.error("No se ha podido iniciar sesión");
      });
  };

  const isFormValid = () => {
    return Object.values(formData).every(value => value !== '');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'primary.main',
        pt: 2,
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 8,
            boxShadow: 1,
            pl: 2,
            pr: 2,
            pb: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '50%',
            }}
            alt="Fidelidapp Logo"
            src={PadlockIcon}
          />
          <Typography color={'primary'} variant="h7" sx={{ pb: 2, textAlign: 'center', fontWeight: 'bold' }}>
            Ingresa tus datos para iniciar sesión o hazlo con Google:
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Correo Electrónico"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      borderRadius: 4,
                      backgroundColor: '#FFFFFF',
                      '& input': {
                        color: '#5E4C5A',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      borderRadius: 4,
                      backgroundColor: '#FFFFFF',
                      '& input': {
                        color: '#5E4C5A',
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!isFormValid()}
              sx={{
                mt: 3,
                mb: 2,
                borderRadius: 2,
              }}
            >
              Iniciar Sesión
            </Button>


            <Grid item sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
              <GoogleLogin useOneTap={true} onSuccess={handleGoogleSignIn} onError={errorMessage} />
            </Grid>
          </Box>
          <Grid item sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography >
            ¿No tienes cuenta? <Link href="/signup">Registrate</Link>
          </Typography>    
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default SignIn;
