import { createTheme } from '@mui/material/styles';

const landingTheme = createTheme({
  palette: {
    primary: {
      main: '#5c7898',
    },
    secondary: {
      main: '#3E7CB1',
    },
    tertiary: {
      main: '#o74a91',
    },
    quaternary: {
      main: '#81a4cd',
    },
    background: {
      default: '#eef1f4',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

export default landingTheme;
