import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Box, Button } from '@mui/material';
import api from '../../../utils/api'; // Adjust the import according to your project structure
import { toast } from 'react-toastify';

const fetchExistingAppointments = async (agendaId) => {
    try {
        const response = await api.get(`/api/agenda/getExistingAppointments/${agendaId}`);
        return response.data.appointments;
    } catch (error) {
        console.error('Error fetching existing appointments:', error);
        return [];
    }
};

const ExistingAppointments = ({ agendaId }) => {
    const [appointments, setAppointments] = useState([])
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getAppointments = async () => {
            const fetchedAppointments = await fetchExistingAppointments(agendaId);
            if (fetchedAppointments.length > 0) {
                await setIsAdmin(true);
                console.log(isAdmin)
            }
            setAppointments(fetchedAppointments);
            setLoading(false);
        };

        getAppointments();
    }, [agendaId]);

    const handleCancel = async (appointmentId) => {
        //Set alert to confirm
        const confirm = window.confirm('Are you sure you want to cancel this appointment?');
        if (!confirm) {
            return;
        } try {
            await api.post(`/api/agenda/cancelAppointment/${appointmentId}`);
            toast.success('Appointment canceled successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error canceling appointment:', error);
            toast.error('Error canceling appointment');
        }
    };

    const handleConfirm = async (appointmentId) => {
        //Set alert to confirm
        const confirm = window.confirm('Are you sure you want to confirm this appointment?');
        if (!confirm) {
            return;
        } try {
            await api.post(`/api/agenda/confirmAppointment/${appointmentId}`);
            toast.success('Appointment confirmed successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error canceling appointment:', error);
            toast.error('Error canceling appointment');
        }
    }

    const handleComplete = async (appointmentId) => {
        //Set alert to confirm
        const confirm = window.confirm('Are you sure you want to complete this appointment?');
        if (!confirm) {
            return;
        } try {
            await api.post(`/api/agenda/completeAppointment/${appointmentId}`);
            toast.success('Appointment completed successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error completing appointment:', error);
            toast.error('Error completing appointment');
        }
    }

    const handleNoShow = async (appointmentId) => {
        //Set alert to confirm
        const confirm = window.confirm('Are you sure you want to mark this appointment as no show?');
        if (!confirm) {
            return;
        } try {
            await api.post(`/api/agenda/noShowAppointment/${appointmentId}`);
            toast.success('Appointment marked as no show successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error canceling appointment:', error);
            toast.error('Error canceling appointment');
        }
    }

    if (!isAdmin) return null

    return (
        <Box mt={4} mb={4}>
            <Typography variant="h5" component="div" gutterBottom>
                Citas Existentes
            </Typography>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{}}>
                                <TableCell>Email del Cliente</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Hora Inicio</TableCell>
                                <TableCell>Hora Fin</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appointments.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <Typography>No hay citas disponibles.</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                appointments.map((appointment) => (
                                    <TableRow key={appointment._id}>
                                        <TableCell>{appointment.clientId.email}</TableCell>
                                        <TableCell>{appointment.startTime.split("T")[0]}</TableCell>
                                        <TableCell>{appointment.startTime.split("T")[1].split(":00.")[0]}</TableCell>
                                        <TableCell>{appointment.endTime.split("T")[1].split(":00.")[0]}</TableCell>
                                        <TableCell>{appointment.status}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {appointment.status === 'Past' || appointment.status === 'Confirmed' ? (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        size='small'
                                                        onClick={() => handleComplete(appointment._id)}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Completar
                                                    </Button>

                                                    <Button
                                                        variant="outlined"
                                                        size='small'
                                                        color="error"
                                                        onClick={() => handleNoShow(appointment._id)}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        No Show
                                                    </Button>
                                                </>
                                            ) : appointment.status === 'Completed' ? (<></>) : (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        size='small'
                                                        onClick={() => handleConfirm(appointment._id)}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Confirmar
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        size='small'
                                                        color="error"
                                                        onClick={() => handleCancel(appointment._id)}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </>)
                                            }

                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ExistingAppointments;
