import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import key2background from '../../../assets/fondodellave2.png';

const FAQSection = () => {
  const faqs = [
    {
      question: '¿Cómo puedo crear una promoción?',
      answer: 'Para crear una promoción, simplemente accede a tu cuenta y selecciona la opción "Crear Promoción" en el panel de control. Completa los detalles de la promoción y guárdala.',
    },
    {
      question: '¿Cuántas promociones puedo crear con el plan gratuito?',
      answer: 'Con el plan gratuito, puedes crear una promoción por cuenta. Si necesitas más, considera actualizar a uno de nuestros planes premium.',
    },
    {
      question: '¿Cómo se gestionan los clientes?',
      answer: 'Puedes gestionar tus clientes entra a una promoción haciendo click, donde podrás agregar, editar y eliminar información de clientes.',
    },
    {
      question: '¿Puedo personalizar mis promociones?',
      answer: 'Sí, puedes personalizar tus promociones con diferentes tipos de descuentos, ofertas especiales, imagenes y mensajes personalizados para tus clientes.',
    },
    {
      question: '¿Cómo puedo ver los reportes de mis campañas?',
      answer: 'Los reportes de tus campañas estarán disponibles en el panel de control, donde puedes ver el rendimiento de cada promoción y el comportamiento de tus clientes.',
    },
  ];

  return (
    <Container sx={{ py: 6 }}>
      <Typography variant="h3" gutterBottom color="primary" sx={{ textAlign: 'center' }}>
        Preguntas Frecuentes
      </Typography>
      <Box
        sx={{
          py: 8,
          backgroundImage: `url(${key2background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '66vh',
        }}>
        <Box sx={{ maxWidth: '90%', width: '100%', mx: 'auto', px: 2 }}>
          {faqs.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default FAQSection;
