import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify'; // Importa toast para retroalimentación
import api from '../../../utils/api'; // Ajusta la importación según la estructura de tu proyecto

const dayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const AgendaList = ({ agendas }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopyLink = (agendaId) => {
    const agendaLink = `${window.location.origin}/agendas/${agendaId}`;
    navigator.clipboard.writeText(agendaLink).then(() => {
      toast.success('Link copiado al portapapeles'); // Retroalimentación al usuario
    }).catch(() => {
      toast.error('Error al copiar el link');
    });
  };

  const handleDeleteAgenda = async (agendaId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta agenda?');
    if (!confirmDelete) return;
    try {
      await api.delete(`/api/agenda/${agendaId}`);
      toast.success('Agenda eliminada correctamente, se refrescará la página');
      setTimeout(() => {
        window.location.reload();
      }, 2 * 1000);
    } catch (error) {
      console.error('Error deleting agenda:', error);
      toast.error('Error al eliminar la agenda');
    }
  };

  return (
    <TableContainer component={Paper}>
      {agendas.length === 0 ? (
        <Box sx={{ textAlign: 'center', padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            No hay agendas disponibles, empieza creando una en el botón de abajo.
          </Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  color: 'white',
                  fontWeight: 'bold',
                  width: isSmallScreen ? '50%' : 'auto',
                }}
              >
                Nombre
              </TableCell>
              {!isSmallScreen && (
                <>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Duración del Evento
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Días Disponibles
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Horas Disponibles
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Slots
                  </TableCell>
                </>
              )}
              <TableCell
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  color: 'white',
                  fontWeight: 'bold',
                  width: isSmallScreen ? '50%' : 'auto',
                }}
              >
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agendas.map((agenda) => (
              <TableRow key={agenda._id}>
                <TableCell component={Link} to={`/agendas/${agenda._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {agenda.name}
                </TableCell>
                {!isSmallScreen && (
                  <>
                    <TableCell>{agenda.eventDuration} minutos</TableCell>
                    <TableCell>{agenda.availableDays.map(day => dayMap[day]).join(', ')}</TableCell>
                    <TableCell>
                      {agenda.availableHours.map(hour => `${hour.start} - ${hour.end}`).join(', ')}
                    </TableCell>
                    <TableCell>{agenda.slots}</TableCell>
                  </>
                )}
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <IconButton component={Link} to={`/agendas/${agenda._id}`} color="primary">
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleCopyLink(agenda._id)} color="primary">
                      <ShareIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteAgenda(agenda._id)} color="primary">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default AgendaList;
