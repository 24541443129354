import React from 'react';
import { Box } from '@mui/material';
import { LandingSection } from './components/LandingSection';
import { ComoFuncionaSection } from './components/ComoFuncionaSection';
import { ReviewsSection } from './components/ReviewsSection';
import { MockupsSection } from './components/MockupsSection';
import BenefitsMetricsSection from './components/BenefitsMetricsSection';
import UseCasesSection from './components/UseCasesSection';
import FeaturesSection from './components/FeaturesSection';
import { Pricing } from './components/Pricing';
import FAQSection from './components/FAQSection';
import { ContactForm } from './components/ContactForm';
import WhatsAppButton from './components/WhatsAppButton.js';


export const LandingServices = () => {
    return (
        <Box>

            <Box id="LandingSection">
                <LandingSection />
            </Box>
            <Box id="FeaturesSection">
                <FeaturesSection />
            </Box>
            <Box id="ComoFuncionaSection">
                <ComoFuncionaSection />
            </Box>
            <Box id="UseCasesSection">
                <UseCasesSection />
            </Box>
            <Box id="ReviewsSection">
                <ReviewsSection />
            </Box>
            {/* <Box id="MockupsSection">
                    <MockupsSection />
                </Box> */}
            <Box id="BenefitsMetricsSection">
                <BenefitsMetricsSection />
            </Box>
            <Box id="PricingSection">
                <Pricing />
            </Box>
            <Box id="FAQSection">
                <FAQSection />
            </Box>
            <Box id="ContactFormSection">
                <ContactForm />
            </Box>
            <WhatsAppButton /> {/* Floating WhatsApp button */}
        </Box>
    );
};
