import React from 'react';
import { Box,Container, Typography, Grid, Card, CardContent, Icon } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';

const FeaturesSection = () => {
  // Define an array of features with title, description, and icon
  const features = [
    {
      title: 'Creación de Promociones',
      description: 'Diseña promociones personalizadas que atraigan y retengan clientes.',
      icon: <LocalOfferIcon fontSize="large" color='secondary' />,
    },
    {
      title: 'Gestión de Clientes',
      description: 'Administra de manera eficiente tu base de datos de clientes.',
      icon: <PeopleIcon fontSize="large" color='secondary'/>,
    },
    {
      title: 'Reportes y Análisis',
      description: 'Obtén insights detallados sobre el rendimiento de tus campañas de fidelización.',
      icon: <BarChartIcon fontSize="large" color='secondary' />,
    },
    {
      title: 'Notificaciones Sencillas',
      description: 'Envía mensajes a tus clientes para mantenerlos informados y comprometidos.',
      icon: <NotificationsIcon fontSize="large" color='secondary'/>,
    },
    {
      title: 'Integraciones',
      description: 'Conecta FidelidApp con tus herramientas existentes para una experiencia fluida.',
      icon: <IntegrationInstructionsIcon fontSize="large" color='secondary'/>,
    },
    {
      title: 'Satisfacción de Clientes',
      description: 'Aumenta la satisfacción de tus clientes con un sistema moderno y eficiente.',
      icon: <SentimentSatisfiedIcon fontSize="large" color='secondary'/>,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ textAlign: 'center', py: 12 }}>
      <Typography variant="h3" gutterBottom color={'primary'} sx={{ mb: 6 }}>
        Funcionalidades y Características
      </Typography>
      <Grid container spacing={1} justifyContent="center" alignItems="stretch">
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card elevation={6} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography variant="h5" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturesSection;
