import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Fab } from '@mui/material';

const WhatsAppButton = () => {
  const whatsappNumber = "56996706983"; // Replace with your WhatsApp number
  const message = "¡Hola! Me gustaría obtener más información sobre Fidelizarte y sus servicios de programas de fidelización. ¡Gracias!"; 

  return (
    <Fab
      color="success"
      aria-label="whatsapp"
      href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        width: 70,  // Increase width of the button
        height: 70, // Increase height of the button
      }}
    >
      <WhatsAppIcon sx={{ fontSize: 40 }} /> {/* Increase the icon size */}
    </Fab>
  );
};

export default WhatsAppButton;
