import React from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../../assets/LogoBlancoSinFondo.png';

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '75vh',
        backgroundColor: 'primary.main',
      }}
    >
      <CircularProgress color='quaternary'/>
      
    </Box>
  );
};

export default LoadingScreen;
