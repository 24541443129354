import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';

const UseCasesSection = () => {
  // const useCases = [
  //   {
  //     title: 'Evaluación Inicial',
  //     description: 'Comenzamos con un análisis profundo de tu negocio y tus clientes para entender tus necesidades y desafíos específicos.',
  //     details: {
  //      problema: 'Los salones de uñas enfrentan frecuentemente la falta de citas recurrentes y la fidelización de clientes.',
  //       promocion: 'Se creó una promoción donde los clientes que reserven cuatro citas consecutivas obtienen un 20% de descuento en la siguiente cita.',
  //       obsequio: 'A los clientes que completan la promoción se les regaló un set de uñas decorativas.',
  //       resultados: 'Un plan de fidelización personalizado alineado con tus objetivos',
  //     },
  //     image: 'https://images.pexels.com/photos/7755238/pexels-photo-7755238.jpeg',
  //   },
  //   {
  //     title: 'Restaurantes',
  //     description: 'Incrementa las reservas y mejora la experiencia del cliente con promociones y reservas online.',
  //     details: {
  //       problema: 'Los restaurantes suelen tener dificultades para llenar mesas durante los días de semana.',
  //       promocion: 'Se implementó una promoción de "Martes de Descuento", ofreciendo un 15% de descuento en la factura total para reservas realizadas en línea.',
  //       obsequio: 'Se ofreció una bebida de cortesía a los clientes que participaron en la promoción.',
  //       resultados: 'El restaurante vio un aumento del 40% en las reservas de los martes y un incremento del 25% en la satisfacción del cliente.',
  //     },
  //     image: 'https://images.pexels.com/photos/262978/pexels-photo-262978.jpeg?auto=compress&cs=tinysrgb&w=800',
  //   },
  //   {
  //     title: 'Peluquerías',
  //     description: 'Facilita la gestión de horarios y servicios, y ofrece descuentos a clientes habituales.',
  //     details: {
  //       problema: 'Las peluquerías a menudo luchan por llenar sus horarios durante las horas no pico.',
  //       promocion: 'Se lanzó una promoción de "Horario Feliz" donde los clientes que reservan servicios entre las 2 y las 4 de la tarde obtienen un 10% de descuento.',
  //       obsequio: 'Los clientes recibieron un producto de cuidado capilar de regalo al participar en la promoción.',
  //       resultados: 'La peluquería logró aumentar la ocupación de horas no pico en un 30% y mejorar la fidelidad del cliente en un 15%.',
  //     },
  //     image: 'https://images.pexels.com/photos/1319461/pexels-photo-1319461.jpeg',
  //   },
  //   {
  //     title: 'Estudios de Tatuajes',
  //     description: 'Aumenta las reservas y fideliza a tus clientes ofreciendo promociones y descuentos exclusivos.',
  //     details: {
  //       problema: 'Los estudios de tatuajes a menudo enfrentan la fluctuación en la cantidad de reservas y la lealtad de los clientes.',
  //       promocion: 'Se lanzó una promoción de "Tatuaje de Referencia" donde los clientes que refieran a un amigo obtienen un 15% de descuento en su siguiente tatuaje.',
  //       obsequio: 'A los clientes que refirieron amigos se les regaló una sesión de retocado gratuito para sus tatuajes.',
  //       resultados: 'El estudio de tatuajes experimentó un aumento del 25% en nuevas reservas y una mejora del 30% en la retención de clientes.',
  //     },
  //     image: 'https://images.pexels.com/photos/4125659/pexels-photo-4125659.jpeg',
  //   },
  // ];

  const useCases = [
    {
      title: '1. Evaluación Inicial',
      description: 'Comenzamos con un análisis profundo de tu negocio y tus clientes para entender tus necesidades y desafíos específicos.',
      details: {
        problema: 'Identificamos los principales retos que enfrentan los negocios, como la falta de fidelización de clientes y la baja recurrencia de citas o reservas.',
        promocion: 'Proponemos promociones estratégicas que incentiven la repetición de compra y aumenten la lealtad del cliente.',
        obsequio: 'Diseñamos incentivos atractivos para los clientes que completen la promoción, como descuentos o regalos exclusivos.',
        resultados: 'Desarrollamos un plan de fidelización personalizado alineado con tus objetivos de negocio.',
      },
      image: 'https://images.pexels.com/photos/7755238/pexels-photo-7755238.jpeg',
    },
    {
      title: '2. Implementación',
      description: 'Configuramos tu cuenta y promociones en Fidelidapp, asegurando una integración sin problemas con tu operación actual.',
      details: {
        problema: 'Nos encargamos de la integración técnica y operativa, eliminando fricciones en la adopción de nuevas herramientas.',
        promocion: 'Personalizamos las promociones para maximizar su efectividad en tu base de clientes.',
        obsequio: 'Definimos claramente los incentivos para garantizar una experiencia positiva tanto para el negocio como para el cliente.',
        resultados: 'Tu programa de fidelización está listo para funcionar sin contratiempos, optimizando cada aspecto para lograr los mejores resultados.',
      },
      image: 'https://images.pexels.com/photos/262978/pexels-photo-262978.jpeg?auto=compress&cs=tinysrgb&w=800',
    },
    {
      title: '3. Capacitación ',
      description: 'Capacitamos a tu equipo para maximizar el uso de Fidelidapp y lanzamos la primera promoción piloto.',
      details: {
        problema: 'Aseguramos que todo el equipo esté alineado y capacitado para sacar el máximo provecho de Fidelidapp.',
        promocion: 'Lanzamos promociones piloto para probar la efectividad y hacer ajustes según sea necesario.',
        obsequio: 'Proveemos materiales y recursos adicionales para reforzar el conocimiento adquirido durante la capacitación.',
        resultados: 'Tu equipo está preparado y motivado, listo para interactuar con los clientes usando Fidelidapp de manera efectiva.',
      },
      image: 'https://images.pexels.com/photos/1319461/pexels-photo-1319461.jpeg',
    },
    {
      title: '4. Lanzamiento',
      description: 'Seguimos de cerca el desempeño de las promociones y ajustamos la estrategia según los datos recolectados.',
      details: {
        problema: 'Evaluamos continuamente los resultados para detectar áreas de mejora.',
        promocion: 'Hacemos ajustes en las promociones para asegurar que sigan siendo relevantes y efectivas.',
        obsequio: 'Adaptamos los incentivos según el comportamiento del cliente y los objetivos del negocio.',
        resultados: 'Programas de fidelización efectivos que evolucionan con tu negocio, garantizando resultados sostenibles.',
      },
      image: 'https://images.pexels.com/photos/4125659/pexels-photo-4125659.jpeg',
    },
    {
      title: '5. Éxito Continuo',
      description: 'Con nuestro soporte continuo y mejoras constantes, tu negocio disfruta de un crecimiento sostenido en la lealtad y satisfacción del cliente.',
      details: {
        problema: 'Nos aseguramos de que tu programa de fidelización se mantenga actualizado y efectivo a lo largo del tiempo.',
        promocion: 'Incorporamos nuevas promociones y estrategias basadas en las tendencias del mercado y el comportamiento del cliente.',
        obsequio: 'Ofrecemos soporte continuo y actualizaciones para mantener la plataforma funcionando de manera óptima.',
        resultados: 'Un aumento tangible en la retención de clientes y en el valor de vida del cliente (CLV), asegurando un crecimiento sostenido para tu negocio.',
      },
      image: 'https://images.pexels.com/photos/4569877/pexels-photo-4569877.jpeg',
    },
  ];

  const [selectedCase, setSelectedCase] = useState('1. Evaluación Inicial',);

  const handleCaseClick = (caseName) => {
    setSelectedCase(caseName === selectedCase ? null : caseName);
  };

  const renderContent = () => {
    if (!selectedCase) {
      return <Typography variant="body1" gutterBottom sx={{ height: '100%' }}>Elige un caso de éxito para ver detalles.</Typography>;
    }

    const selectedUseCase = useCases.find((useCase) => useCase.title === selectedCase);

    return (
      <Box sx={{ textAlign: 'left', padding: 2 }}>
        <Typography variant="h4" color={'primary'} sx={{ mb: 2 }}>
          {selectedUseCase.title}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.description}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.problema}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.promocion}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.obsequio}
        </Typography>
        <Typography variant="body1">
          {selectedUseCase.details.resultados}
        </Typography>
        <img src={selectedUseCase.image} alt={selectedUseCase.title} style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', marginTop: 16 }} />
      </Box>
    );
  };

  return (
    <Container sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h3" gutterBottom color="primary" sx={{ mb: 2 }}>
        Tu viaje hacia el éxito con Fidelidapp
      </Typography>
      <Typography variant="body1" gutterBottom color="secondary" sx={{ mb: 6, fontStyle: 'italic' }}>
      Un camino claro y sencillo que te lleva desde la implementación inicial hasta la fidelización efectiva de tus clientes. 
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          borderRadius: 8,
          padding: 3,
          border: '1px solid black',
        }}
      >
        <Grid item xs={12} md={3}>
          {useCases.map((useCase) => (
            <Card
              key={useCase.title}
              sx={{
                cursor: 'pointer',
                mb: 2,
                backgroundColor: selectedCase === useCase.title ? '#e0e0e0' : 'white',
                borderLeft: selectedCase === useCase.title ? '4px solid #3E7CB1' : 'none',
                textAlign: 'center', // Center align the text
              }}
              onClick={() => handleCaseClick(useCase.title)}
            >
              <CardContent>
                <Typography variant="h6">
                  {useCase.title}
                </Typography>
                <Button onClick={() => handleCaseClick(useCase.title)} color="primary">
                  Ver más
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
        <Grid item xs={12} md={9}>
          <Card
            sx={{
              mb: 1,
              backgroundColor: 'white',
            }}
          >
            {renderContent()}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UseCasesSection;
