import React, { useState } from 'react';
import { Button, Container, Typography, Box, Card, CardContent, Grid, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import  api from '../../../utils/api'; // Importar la función API

const reviews = [
  {
    title: "Clinica Dental - DaVinci",
    content: "Con el equipo de FidelidApp hemos logrado fidelizar a pacientes de una manera innovadora, logrando aumentar las tasas de ocupación y de tratamientos terminados.",
    author: "Dr. Eduardo Calderón, Dentista y Gerente",
    image: "https://media.licdn.com/dms/image/v2/C5103AQGMJteKxdOMSA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1516942669644?e=1731542400&v=beta&t=qE9faWuBi1Y_CL7cU_DH5Wb3xmL2uArZjOdwODtwTCk"
  },
  {
    title: "Inugami - Ramen Restobar",
    content: "Gracias al equipo de FidelidApp hemos logrado construir una base de clientes fidelizables, y proponer estrategias de fidelización adaptadas a cada segmento, logrando un aumento significativo en la  de clientes.",
    author: "Rodrigo Valenzuela, Jefe de Servicio",
    image: "https://media.licdn.com/dms/image/v2/C4E03AQE6HIwD7gCp-g/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1618076649806?e=1731542400&v=beta&t=aBGTXFMY-QITc4hUtqfKM8PxFurY7hwCqBMX3Zl-__8"
  },
  {
    title: "Ewaffle - Cursos E-learning",
    content: "A través de una segmentción de clientes, hemos logrado proponer una estrategia de fidelización adaptada a cada segmento, logrando un aumento significativo en la tasa de renteción de clientes.",
    author: "Hayermín León, Líder de Proyectso E-learning",
    image: "https://media.licdn.com/dms/image/v2/D4E03AQGJFX9nEvXSbg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1708364692764?e=1731542400&v=beta&t=JC6H-foCPNZNfSBGCJ5z-5j2O6KoitfoQ6iST31xmZ8"
  }
];

export const ReviewsSection = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '' });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
        await api.post('/auth/contact', formData); // Llamada a la API para enviar el formulario
        alert('Solicitud enviada con éxito');
        handleClose();
    } catch (error) {
        console.error('Error al enviar la solicitud', error);
        alert('Hubo un error al enviar la solicitud');
    }
};

  return (
    <Container sx={{ textAlign: 'center', py: 4 }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={10}>
          <Button variant="contained" color="secondary" size='large' onClick={handleClickOpen} sx={{ borderRadius: '20px', mb: 5, minWidth: '66%' }}>
            Comienza tu viaje hoy
          </Button>
        </Grid>

        {reviews.map((review, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar src={review.image} alt={review.author} sx={{ mr: 2 }} />
                  <Typography variant="h6" gutterBottom color={'primary'} textAlign={'left'}>
                    {review.title}
                  </Typography>
                </Box>
                <Typography color="textSecondary" textAlign={'left'} sx={{ mb: 2, fontStyle: 'italic' }}>
                  {review.content}
                </Typography>
                <Typography color="textSecondary" sx={{ mt: 2 }} textAlign={'right'}>
                  {review.author}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={10} sm={10} md={10} sx={{ mt: 5 }} >
          <Typography variant="h4" color="textSecondary" style={{ fontStyle: 'italic' }}>
            Las empresas que implementan programas de fidelidad experimentan un aumento del 30% en las ventas y una retención de clientes mejorada en un 40%.
          </Typography>
        </Grid>
      </Grid>
      {/* Pop-up con el formulario */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Compártenos tus datos y te contactaremos</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nombre"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Correo Electrónico"
            name="email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Mensaje"
            name="message"
            type="text"
            fullWidth
            value={formData.message}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
