import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Box } from '@mui/material';
import api from '../../utils/api'; // Adjust the import based on your project structure
import { toast } from 'react-toastify';

const CreateAgendaForm = () => {
    const [name, setAgendaName] = useState('');
    const [eventDuration, setEventDuration] = useState('');
    const [availableDays, setAvailableDays] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);
    const [slots, setSlots] = useState([]);
    const navigate = useNavigate();
    
    const handleDayChange = (event) => {
        setAvailableDays(event.target.value);
    };

    const handleHourChange = (event) => {
        setAvailableHours(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/api/agenda/createagenda', {
                name,
                slots,
                eventDuration,
                availableDays,
                availableHours,
            });
            console.log('Agenda creada correctamente:', response.data);
            toast.success("Agenda creada correctamente")
            navigate('/home');
        } catch (error) {
            console.error('Error creating agenda:', error);
            toast.error("No se ha podido crear la agenda")
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <TextField
                label="Nombre de Agenda"
                value={name}
                onChange={(e) => setAgendaName(e.target.value)}
                fullWidth
                margin="normal"
                required
            />
            
            <TextField
                label="Cupos"
                value={slots}
                onChange={(e) => setSlots(e.target.value)}
                fullWidth
                margin="normal"
                type="number"
                required
            />

            <TextField
                label="Duración (Minutos)"
                value={eventDuration}
                onChange={(e) => setEventDuration(e.target.value)}
                fullWidth
                margin="normal"
                type="number"
                required
            />

            <FormControl fullWidth margin="normal">
                <InputLabel>Días Disponibles</InputLabel>
                <Select
                    multiple
                    value={availableDays}
                    onChange={handleDayChange}
                    renderValue={(selected) => selected.join(', ')}
                    required
                >
                    {['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'].map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <InputLabel>Horas Disponibles</InputLabel>
                <Select
                    multiple
                    value={availableHours}
                    onChange={handleHourChange}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                            {hour}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Crear Agenda
            </Button>
        </Box>
    );
};

export default CreateAgendaForm;
