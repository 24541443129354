import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, AppBar, Toolbar, Typography, IconButton, Box, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Logo from '../assets/LogoTSinFondoBlanco.png';
import Fidelidapp from '../assets/LogosecundarioBlancosinfondo.png';
import api from '../utils/api'; // Make sure the path to your api utility is correct
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';


export const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [auth, setAuth] = useState(false);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    const [accountId, setAccountId] = useState('');
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    let location = useLocation();


    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await api.get('/auth/current');
                setAccountId(response.data.accounts._id);
                setAuth(true); // User is authenticated
            } catch (error) {
                setAuth(false); // User is not authenticated
            }
        };
        fetchCurrentUser();
    }, [location]);

    const handleAddUser = async () => {
        try {
            await api.post(`/accounts/add/${accountId}`, { email });
            handleClose();
            toast.success(`${email} ha sido agregado correctamente`);
        } catch (error) {
            console.error('Error adding user to account:', error.response.data.error);
            toast.error(error.response.data.error);
        }
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleScrollToTop = () => {
        scroll.scrollToTop();
    };

    const handleUserMenuOpen = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await api.get('/auth/logout');
            toast.success("Usuario deslogueado correctamente");
            setAuth(false);
            navigate('/');
        } catch (error) {
            console.error('Error logging out user:', error);
            toast.error("No se ha podido cerrar sesión");
        }
    };


    return (
        <AppBar position="static" sx={{ boxShadow: 2, margin: 0, pt: 2, pb: 2 }}>
            <Toolbar sx={{ minHeight: 50, maxHeight: 60 }}>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item xs={8} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Logo} alt="FidelidApp" style={{ maxWidth: '30%' }} onClick={() => navigate('/home')} />
                        <img src={Fidelidapp} alt="FidelidApp" style={{ maxWidth: '45%' }} onClick={handleScrollToTop} />
                    </Grid>
                    <Grid item xs={4} md={8} sx={{ textAlign: { xs: 'center', md: 'right' }, mt: { xs: 1, md: 0 } }}>
                        <Box>
                            <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)} sx={{ display: { md: 'none' } }}>
                                <MenuIcon />
                            </IconButton>
                            <Typography component="nav" sx={{ display: { xs: 'none', md: 'inline' }, color: 'white' }}>
                                {auth ? (
                                    <>
                                        <Box component="span" mx={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/home')}>Home</Box>
                                        <Box component="span" mx={1} sx={{ cursor: 'pointer' }} onClick={() => setDrawerOpen(true)}>Admin</Box>
                                    </>
                                ) : (
                                    <>
                                        <ScrollLink to="LandingSection" smooth={true} duration={500}>
                                            <Box component="span" mx={1} sx={{ cursor: 'pointer' }}>Home</Box>
                                        </ScrollLink>
                                        <ScrollLink to="ComoFuncionaSection" smooth={true} duration={500}>
                                            <Box component="span" mx={1} sx={{ cursor: 'pointer' }}>Cómo Funciona</Box>
                                        </ScrollLink>
                                        <ScrollLink to="PricingSection" smooth={true} duration={500}>
                                            <Box component="span" mx={1} sx={{ cursor: 'pointer' }}>Planes</Box>
                                        </ScrollLink>
                                        <ScrollLink to="ContactFormSection" smooth={true} duration={500}>
                                            <Box component="span" mx={1} sx={{ cursor: 'pointer' }}>Contacto</Box>
                                        </ScrollLink>
                                        <ScrollLink onClick={() => navigate('/signin')}>
                                            <Box component="span" mx={1} sx={{ cursor: 'pointer' }}>Iniciar Sesión</Box>
                                        </ScrollLink>
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <List sx={{ width: 250 }}>
                    {auth ? (
                        <>
                            <ListItem button onClick={() => navigate('/home')}>
                                <ListItemText primary="Home" />
                            </ListItem>
                            <ListItem button onClick={() => navigate('/create')}>
                                <ListItemText primary="Crear Promoción" />
                            </ListItem>
                            <ListItem button onClick={() => setOpen(true)}>
                                <ListItemText primary="Agregar Usuarios" />
                            </ListItem>
                            <ListItem button onClick={() => handleLogout()}>
                                <ListItemText primary="Cerrar Sesión" />
                            </ListItem>
                        </>
                    ) : (
                        <>
                            <ListItem button onClick={handleScrollToTop}>
                                <ListItemText primary="Home" />
                            </ListItem>
                            <ListItem button>
                                <ScrollLink to="ComoFuncionaSection" smooth={true} duration={500} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Cómo Funciona" />
                                </ScrollLink>
                            </ListItem>
                            <ListItem button>
                                <ScrollLink to="PricingSection" smooth={true} duration={500} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Pricing" />
                                </ScrollLink>
                            </ListItem>
                            <ListItem button>
                                <ScrollLink to="ContactSection" smooth={true} duration={500} onClick={toggleDrawer(false)}>
                                    <ListItemText primary="Contact" />
                                </ScrollLink>
                            </ListItem>
                            <ListItem button>
                                <ScrollLink onClick={() => (navigate('/signin'))}>
                                    <Box component="span" >Iniciar Sesión</Box>
                                </ScrollLink>
                            </ListItem>
                        </>
                    )}
                </List>
            </Drawer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Agregar Usuario a la Cuenta</DialogTitle>

                <DialogContent>
                    <Typography variant="body2">
                        Esto agregará otro usuario como administrador de tu cuenta.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email del Usuario"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancelar</Button>
                    <Button onClick={handleAddUser} color="primary">Agregar</Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    );
};
