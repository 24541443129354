import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Modal, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import key2background from '../../../assets/fondocandado2.png';
import  api from '../../../utils/api'; // Importar la función API
import { useNavigate } from 'react-router-dom';

export const ComoFuncionaSection = () => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '' });
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        try {
            await api.post('/auth/contact', formData); // Llamada a la API para enviar el formulario
            alert('Solicitud enviada con éxito');
            navigate('/thank-you');
            handleClose();
        } catch (error) {
            console.error('Error al enviar la solicitud', error);
            alert('Hubo un error al enviar la solicitud');
        }
    };

    return (
        <Box
            sx={{
                textAlign: 'center',
                py: 6, // Reduced padding
                backgroundImage: `url(${key2background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                zIndex: 1, // Add zIndex to ensure content is above the background
            }}
        >
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Grid item xs={10} md={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Flex direction column
                            justifyContent: 'center', // Center content vertically
                            backgroundColor: 'primary.main', // Background color
                            color: 'white', // Text color
                            borderRadius: '20px', // Rounded corners
                            padding: '10px 20px', // Padding
                            textAlign: 'center', // Center text
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Typography variant="h5" align='center' sx={{ mb: 2, mt: 2 }}>
                            Crea programas de fidelización para tus clientes de manera rápida y sencilla.
                        </Typography>
                        <Typography variant="h5" align='center' sx={{ mb: 2 }}>
                            Con Fidelidapp te ayudamos a entender y mejorar la experiencia del cliente.
                        </Typography>
                    </Box>
                </Grid>

                {/* Video Modal */}
                <Grid item xs={10}>
                    <Button variant="contained" color="secondary" size='large' onClick={handleClickOpen} sx={{ borderRadius: '20px', mt: 5, minWidth: '50%' }}>
                        Solicita Tu Demo y Conoce los Beneficios
                    </Button>
                </Grid>
            </Grid>

            {/* Pop-up con el formulario */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Compártenos tus datos y te contactaremos</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Nombre"
                        name="name"
                        fullWidth
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Correo Electrónico"
                        name="email"
                        type="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Mensaje"
                        name="message"
                        type="text"
                        fullWidth
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
