import React from 'react';
import { Container, Typography, Box, Card, CardContent, Grid, Avatar } from '@mui/material';

const reviews = [
  {
    title: "Increíble servicio, ¡recomendado!",
    content: "FidelidApp realmente nos ha ayudado a mantener a nuestros clientes felices y leales.",
    //author: "Cliente Satisfecho",
    image: "https://randomuser.me/api/portraits/men/1.jpg"
  },
  {
    title: "Gran herramienta para la fidelización",
    content: "Hemos visto un aumento significativo en la retención de clientes.",
    //author: "Cliente Feliz",
    image: "https://randomuser.me/api/portraits/women/1.jpg"
  },
  {
    title: "Muy fácil e intuitivo de usar, lo recomiendo!",
    content: "Nuestros clientes agradecen obtener obsequios por su fidelidad.",
    //author: "Cliente Contento",
    image: "https://randomuser.me/api/portraits/men/2.jpg"
  }
];

export const ReviewsSection = () => {
  return (
    <Container sx={{ textAlign: 'center', py: 12 }}>
      <Grid container spacing={4} justifyContent="center">
        {reviews.map((review, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar src={review.image} alt={review.author} sx={{ mr: 2 }} />
                  <Typography variant="h6" gutterBottom color={'primary'} textAlign={'left'}>
                    {review.title}
                  </Typography>
                </Box>
                <Typography color="textSecondary" textAlign={'left'} sx={{ mb: 2, fontStyle: 'italic' }}>
                  {review.content}
                </Typography>
                <Typography color="textSecondary" sx={{ mt: 2 }} textAlign={'right'}>
                  {review.author}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={10} sm={10} md={10} sx={{ mt: 5 }} >
          <Typography variant="h4" color="textSecondary" style={{ fontStyle: 'italic' }}>
            Las empresas que implementan programas de fidelidad experimentan un aumento del 30% en las ventas y una retención de clientes mejorada en un 40%.
          </Typography>
        </Grid>
      </Grid>

    </Container>
  );
};
