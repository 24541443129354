import React, { useState } from 'react';
import { Typography, Container, Grid, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../utils/api';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const NewPromotionForm = () => {
  const [formData, setFormData] = useState({
    title: '', description: '', image: null, imageUrl: '', promotionType: '', visitsRequired: 0, benefitDescription: '', duration: 0, conditions: '', promotionRecurrent: null
  });

  const [errors, setErrors] = useState({});
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Add loading state


  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) { // 5MB in bytes
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'La imagen no puede ser mayor a 5MB.'
      }));
    } else {
      setSelectedImageUrl(URL.createObjectURL(file));
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: ''
      }));
    }
  };

  const handleSubmit = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = 'El título es obligatorio.';
    if (!formData.description) newErrors.description = 'La descripción es obligatoria.';
    if (!formData.conditions) newErrors.conditions = 'Las condiciones son obligatorias.';
    if (!formData.visitsRequired) newErrors.visitsRequired = 'Las visitas requeridas son obligatorias.';
    if (!formData.promotionDuration) newErrors.promotionDuration = 'La validez es obligatoria.';
    if (!formData.promotionType) newErrors.promotionType = 'El tipo de promoción es obligatorio.';
    if (!formData.image) newErrors.image = 'La imagen es obligatoria.';
    if (!formData.promotionRecurrent) newErrors.promotionRecurrent = 'Debes seleccionar si la promoción es recurrente.';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setLoading(true); // Set loading to true when submission starts

      api.post('/api/promotions/create', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          // Redirect to sign-in page after successful promotion creation
          toast.success("Promoción creada correctamente")
          navigate('/home');
        })
        .catch(error => {
          console.error('Error creating promotion:', error);
          toast.error("No se ha podido crear la promoción")
          setLoading(false);
        })
      console.log('Form submitted successfully:', formData);

    }
  };

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ textAlign: 'left' }}>
            Define los datos del programa
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="title"
            label="Título de la promoción"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            sx={{ textAlign: 'left', borderRadius: '250px' }}
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="¿Qué obtendría el cliente?"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            sx={{ textAlign: 'left', borderRadius: '250px' }}
            error={!!errors.description}
            helperText={errors.description}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="visitsRequired"
            label="Compras requeridas"
            value={formData.visitsRequired}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
            error={!!errors.visitsRequired}
            helperText={errors.visitsRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="promotionDuration"
            label="Validez (días)"
            value={formData.promotionDuration}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
            error={!!errors.promotionDuration}
            helperText={errors.promotionDuration}
          />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}>
          <FormControl component="fieldset" error={!!errors.promotionType}>
            <FormLabel id="promotiontype">Elige el tipo de beneficio</FormLabel>
            <RadioGroup
              name="promotionType"
              value={formData.promotionType}
              onChange={handleChange}
            >
              <FormControlLabel value="descuento" control={<Radio />} label="Descuento" />
              <FormControlLabel value="regalo" control={<Radio />} label="Regalo" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}>
          <FormControl component="fieldset" error={!!errors.promotionRecurrent}>
            <FormLabel id="promotionrecurrent">¿Será recurrente? (Se reiniciará automáticamente al finalizar)</FormLabel>
            <RadioGroup
              name="promotionRecurrent"
              value={formData.promotionRecurrent}
              onChange={handleChange}
            >
              <FormControlLabel value="True" control={<Radio />} label="Sí" />
              <FormControlLabel value="False" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="conditions"
            label="Condiciones del programa; estas serán enviadas al cliente por email una vez inscrito."
            value={formData.conditions}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            sx={{ textAlign: 'left', borderRadius: '250px' }}
            error={!!errors.conditions}
            helperText={errors.conditions}
          />
        </Grid>
        <Grid item xs={10} md={10} sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="subtitle1">Sube una imagen de la promoción</Typography>
          <label htmlFor="upload-image-input">
            <Button
              component="span"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              color='secondary'
            >
              Subir imagen
            </Button>
          </label>
          <VisuallyHiddenInput
            id="upload-image-input"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          {errors.image && <Typography color="error">{errors.image}</Typography>}
        </Grid>
        <Grid item xs={10} md={6} sx={{ textAlign: 'center' }}>
          {selectedImageUrl && (
            <div>
              <img src={selectedImageUrl} alt="Selected" style={{ width: '50%', height: 'auto' }} />
            </div>
          )}
        </Grid>
        <Grid item xs={10} sx={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={handleSubmit} sx={{ width: '90%', mt: 4, borderRadius: 4 }} disabled={loading} >
          {loading ? 'Creando Programa...' : 'Enviar'} 
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewPromotionForm;
