import React, { useEffect, useState } from 'react';
import { Card, Container, Grid, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../../utils/api';
import MetricsCard from './MetricsCard';
import UserActions from './UserActions';
import PromotionList from './PromotionList';
import AgendaList from './AgendaList';

const Dashboard = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [agendas, setAgendas] = useState([]);
    const [promotionMetrics, setPromotionMetrics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [PromotionEnabled, setPromotionEnabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userResponse, promotionsResponse, agendaResponse] = await Promise.all([
                    api.get('/auth/current'),
                    api.get('/api/promotions'),
                    api.get('/api/agenda')
                ]);
                
                await setUserData(userResponse.data);
                await setPromotions(promotionsResponse.data.promotions);
                await setAgendas(agendaResponse.data);
                await setPromotionMetrics(promotionsResponse.data.metrics);
                
                if (promotionsResponse.data.metrics.activePromotions < userResponse.data.plan.promotionLimit) {
                    setPromotionEnabled(true);
                }

                
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}></Box>;
    }

    if (error) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>Error: {error.message}</Box>;
    }

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '80vh',
                padding: 2,
            }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Card textAlign="left" sx={{ p: 2 }}>
                        <Typography component="h1" variant="h5" color={'primary'} sx={{ mb: 2 }}>
                            ¡Gracias por unirte a FidelidApp, {userData.name}!
                        </Typography>
                        <Typography component="p" sx={{ mb: 2 }}>
                        Aquí encontrarás herramientas para mejorar la fidelidad de tus clientes. 
                        </Typography>
                        
                        <Typography component="p" sx={{ mb: 2 }}>
                        Plan Actual: {userData.plan.planStatus}    
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <MetricsCard title="Programas Activos" value={promotionMetrics.activePromotions} limit={userData.plan.promotionLimit} />
                </Grid>
                <Grid item xs={6} md={3}>
                    <MetricsCard title="Clientes Registrados" value={promotionMetrics.registeredClients} limit={userData.plan.clientLimit}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <MetricsCard title="Visitas Totales" value={promotionMetrics.totalVisits} /> {/* Adjust with real data if available */}
                </Grid>
                <Grid item xs={6} md={3}>
                    <MetricsCard title="Promociones Canjeadas" value={promotionMetrics.redeemedGifts} /> {/* Adjust with real data if available */}
                </Grid>

                <Grid item xs={12}>
                    <Typography component="p" sx={{ mb: 2 }}>
                        Aquí puedes crear y gestionar tus programas de fidelización. 
                    </Typography>
                    <PromotionList promotions={promotions} />
                </Grid>

                <Grid item xs={12}>
                    <Typography component="p" sx={{ mb: 2 }}>
                        Aquí puedes crear y gestionar tus agendas.
                    </Typography>
                    <AgendaList agendas={agendas} />
                </Grid>

                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <UserActions accountId={userData.accounts._id} navigate={navigate} PromotionEnabled={PromotionEnabled} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;
