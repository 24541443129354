import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import api from '../../utils/api';

function EmailSender() {
  const [csvFile, setCsvFile] = useState(null);
  const [subject, setSubject] = useState('Personalized Message'); // Default subject
  const [template, setTemplate] = useState('Hello [Name],\n\nThis is your [Detail].');

  // Handle file input change
  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  // Handle subject input change
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  // Handle template input change
  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };

  // Send emails function
  const handleSendEmails = async () => {
    if (!csvFile) {
      alert('Please upload a CSV file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('subject', subject); // Add subject to form data
    formData.append('template', template);

    try {
      const response = await api.post('/api/email', formData);

      if (response.status === 200) {
        alert('Emails sent successfully!');
      } else {
        alert('Failed to send emails.');
      }
    } catch (error) {
      alert('Error sending emails: ' + error.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6">
        A continuación podrás crear emails y enviarlos masivamente.
      </Typography>
      <br />
      <Typography variant="body2">
        Usa [Name] y [Detail] para personalizar el mensaje.
      </Typography>
      <br />
      <br />

      <input type="file" accept=".csv" onChange={handleFileChange} />

      <TextField
        label="Email Subject"
        value={subject}
        onChange={handleSubjectChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Email Template"
        multiline
        rows={8}
        value={template}
        onChange={handleTemplateChange}
        fullWidth
        margin="normal"
      />

      <Button variant="contained" color="primary" onClick={handleSendEmails}>
        Send Emails
      </Button>
    </Box>
  );
}

export default EmailSender;
