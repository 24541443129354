import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, CardActions, Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import  api from '../../../utils/api'; // Importar la función API

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            '1 promoción activa',
            '50 clientes activos ',
            'Hasta 3 administradores',
            'Reportes generales y por promoción',
            'Apoyo básico de implementación'
        ],
        buttonText: 'Comienza Gratis',
        buttonVariant: 'contained',
    },
    {
        title: 'Pro ',
        price: '19USD',
        description: [
            '5 promociones activas',
            'Clientes activos ilimitados',
            'Evaluación y Segmentación Inicial',
            'Apoyo en implementación',
            'Reportes generales y por promoción',
            'Envía emails personalizados a tus clientes',
            'Soporte prioritario',
        ],
        buttonText: 'Quiero Comenzar',
        buttonVariant: 'contained',
    },
    {
        title: 'Premium',
        price: '99USD',
        description: [
            'Promociones Ilimitadas',
            'Clientes activos ilimitados',
            'Hasta 5 administradores',
            'Reportes generales y por promoción',
            'Envía emails personalizados a tus clientes',
            'Análisis avanzado de datos a la medida',
            'Account Mangaer dedicado',
        ],
        buttonText: 'Hablemos',
        buttonVariant: 'contained',
    },
];

export const Pricing = () => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '' });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        try {
            await api.post('/auth/contact', formData); // Llamada a la API para enviar el formulario
            alert('Solicitud enviada con éxito');
            handleClose();
        } catch (error) {
            console.error('Error al enviar la solicitud', error);
            alert('Hubo un error al enviar la solicitud');
        }
    };

    return (
        <Container sx={{}}>
            <Typography variant="h3" align='center' color={'primary'} sx={{ mb: 6 }}>
                Planes
            </Typography>
            <Grid container spacing={1} alignItems="flex" >
                {tiers.map((tier) => (
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'Pro (soon)' ? 12 : 12}
                        md={4}
                        sx={{ mt: 5 }}
                    >
                        <Card>
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2 }}>
                                    <Typography component="h2" variant="h5" color="text.primary">
                                        {tier.title}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2 }}>
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${tier.price}
                                    </Typography>
                                    <Typography variant="h6" color="black">
                                        /mes
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 2, width: '100%' }}>
                                    <ul style={{ padding: 0, margin: 0, width: '100%' }}>
                                        {tier.description.map((line) => (
                                            <Typography variant="subtitle1" align="left" key={line} >
                                                <FavoriteIcon sx={{ color: 'primary.main', mr: 1, mb: -1 }} /> {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={tier.buttonVariant} color="primary" onClick={handleClickOpen}>
                                    {tier.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {/* Pop-up con el formulario */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Solicita tu Demo</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Nombre"
                        name="name"
                        fullWidth
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Correo Electrónico"
                        name="email"
                        type="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Mensaje"
                        name="message"
                        type="text"
                        fullWidth
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
