import './App.css';
import { Container } from '@mui/material';
import { LandingPage } from './pages/landing/LandingPage.js';
import SignIn from './pages/auth/SignIn.js';
import SignUp from './pages/auth/SignUp.js';
import Home from './pages/home/Home.js';
import NewPromotionForm from './pages/promotions/NewPromotionForm.js';
import PromotionDetails from './pages/promotions/PromotionDetails.js';
import ClientPromotionVisit from './pages/promotions/ClientPromotionVisit.js';
import Agenda from './pages/agenda/Agenda.js';
import CreateAgendaForm from './pages/agenda/CreateAgendaForm.js';
import ConfirmAppointment from './pages/agenda/components/ConfirmAppointment.js';
import CancelAppointment from './pages/agenda/components/CancelAppointment.js';
import ThankYou from './pages/agenda/components/ThankYou.js';
import { LandingServices } from './pages/landingservices/LandingServices.js';
import EmailSender from './pages/emailSender/emailSender.js';
import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, matchPath } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Make sure to import the CSS for react-toastify
import ReactGA from 'react-ga4';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HelmetProvider } from 'react-helmet-async';



// Initialize Google Analytics
const trackingId = 'G-Q91RG51PRW'; // Replace with your tracking ID
ReactGA.initialize(trackingId);

// Custom hook to track page views
const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_path: location.pathname });
  }, [location]);
};

function AppContent() {
  const location = useLocation();

  // List of routes where Header and Footer should not be displayed
  const noHeaderFooterRoutes = [
    '/signup',
    '/thank-you'
  ];

  // Check if the current route is in the list
  const hideHeaderFooter = noHeaderFooterRoutes.includes(location.pathname) ||

    matchPath('/agendas/:pid', location.pathname) ||
    matchPath('/agenda/confirm/:appointmentId', location.pathname) ||
    matchPath('/agenda/cancel/:appointmentId', location.pathname) ||
    matchPath('/promotions/:pid', location.pathname) ||
    matchPath('/promotions/:cid/:pid', location.pathname);

  return (
    <>
      {!hideHeaderFooter && <Header />}
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/home" element={<Home />} />
        <Route path="/create" element={<NewPromotionForm />} />
        <Route path="/promotions/:pid" element={<PromotionDetails />} />
        <Route path="/promotions/:cid/:pid" element={<ClientPromotionVisit />} />
        <Route path="/agendas/:agendaId" element={<Agenda />} />
        <Route path="/CreateAgenda/" element={<CreateAgendaForm />} />
        <Route path="/agenda/confirm/:appointmentId" element={<ConfirmAppointment />} />
        <Route path="/agenda/cancel/:appointmentId" element={<CancelAppointment />} />
        <Route path="/email-marketing" element={<EmailSender />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/" element={<LandingServices />} />
      </Routes>
      <PageTracking /> {/* Include the PageTracking component inside the Router */}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          minWidth: '100vw',
          overflow: 'auto', // Allow scrolling if content overflows
        }}
      >
        <ToastContainer />
        <Router>
          <AppContent />
        </Router>
      </Container>
    </HelmetProvider>
  );
}

// Component for tracking page views
const PageTracking = () => {
  usePageTracking(); // Call the custom hook
  return null; // Return null because this component doesn't render anything
};

export default App;
