import React from 'react';
import { IconButton, Grid, Typography, Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const StatisticsCard = ({ title, count = 0 }) => (
  <Box component="div" sx={{ display: 'inline' }}>
    <Card sx={{ minWidth: 150, minHeight: 100 }}>
      <CardContent>
        <Typography variant="body1">
          {title}
        </Typography>
        <Typography variant="h6">
          {count}
        </Typography>
      </CardContent>
    </Card>
  </Box>
);

const PromotionStats = ({ statistics, pid }) => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h5">
          Métricas de la Promoción
        </Typography>
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
        <StatisticsCard title="Total Clientes" count={statistics.TotalClients} />
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
        <StatisticsCard title="Clientes Activos" count={statistics.ActiveClients} />
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
        <StatisticsCard title="Promociones Expiradas" count={statistics.ExpiredClients} />
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
        <StatisticsCard title="Obsequios Canjeados" count={statistics.RedeemedClients} />
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
        <StatisticsCard title="Visitas Totales" count={statistics.TotalVisit} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ marginTop: '20px' }}>Visitas por Día</Typography>
        <ResponsiveContainer width="100%" height={300} minWidth={300}>
          <LineChart data={statistics.visitsPerDay}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="visits" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'center' }}>Lista de Clientes</Typography>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table sx={{ minWidth: '80%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }} >Email</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="center">Estado</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="center">Tarjeta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statistics.clientList.map((client, index) => {
                const [email, status, cid] = client.split(' , ');
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" > 
                      {email}
                    </TableCell>
                    <TableCell align="center">
                      {status}
                    </TableCell>
                    <TableCell align="center">
                    <IconButton component={Link} to={`/promotions/${cid}/${pid}`} color="primary">
                      <CardGiftcardIcon />
                    </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PromotionStats;
