import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardContent, Container, Grid, Typography, Paper, Box, TextField, CircularProgress, IconButton, Divider } from '@mui/material';
import { toast } from 'react-toastify';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import api from '../../utils/api'; // Make sure the path to your api utility is correct
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import PromotionStats from './components/PromotionStats';

const PromotionDetails = () => {
  const { pid } = useParams(); // Get the promotionid parameter from the URL
  const [promotion, setPromotion] = useState(null); // State variable to store promotion data
  const [loading, setLoading] = useState(true); // State variable to track loading state
  const [email, setEmail] = useState(''); // State variable to store the client email
  const [isAdmin, setIsAdmin] = useState(false); // State variable to track if the user is an admin
  const [processing, setProcessing] = useState(false); // State variable to track processing state
  const navigate = useNavigate();


  useEffect(() => {
    // Fetch promotion data based on promotionid
    const fetchPromotionDetails = async () => {
      
      try {
        const [promotionResponse] = await Promise.all([
          api.get(`/api/promotions/${pid}`),
          //api.get('/auth/current')
        ]);
        
        setPromotion(promotionResponse.data); // Update promotion state with fetched data
        //If data.statistics has data set isAdmin to true
        if (promotionResponse.data.statistics) {
          setIsAdmin(true);
        }


        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching promotion details:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchPromotionDetails(); // Call the function to fetch promotion details
  }, [pid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true); // Disable button and show processing message
    try {
      const client = await api.post('/api/promotions/client', { clientEmail: email, promotionId: pid });
      toast.success('El cliente ha sido agregado correctamente');
      setProcessing(false);
    } catch (error) {
      toast.error('Error al agregar el cliente');
      setProcessing(false);
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Enlace copiado al portapapeles');
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/api/promotions/${pid}`);
      toast.success('Promoción eliminada correctamente');
      navigate('/home');
    } catch (error) {
      toast.error('Error al eliminar la promoción');
    }
  };


  if (loading) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          border: 0,
          padding: 5,
        }}
      >
        <CircularProgress />
        <Typography sx={{ marginTop: 2 }}>Cargando detalles de la promoción...</Typography>
      </Container>
    );
  }

  if (!promotion) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          border: 0,
          padding: 5,
        }}
      >
        <Typography sx={{ marginTop: 2 }}>No se encontró la promoción.</Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        minHeight: '66vh',
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} sx={{ textAlign: 'left' }}>
          <Typography variant="h5" >
            {promotion.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
          <Typography variant="h6" >Detalles de la Promoción:</Typography>
          <br />
          <Typography component="p" dangerouslySetInnerHTML={{ __html: promotion.description.replace(/\r\n|\r|\n/g, '<br />') }} />
          <br />
          <Typography component="p" >La promoción se activa con: {promotion.visitsRequired} visitas en {promotion.promotionDuration} días</Typography>
          <br />
          <Typography component="p" >Registrate a continuación para ser parte de esta promoción!</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <img src={promotion.imageUrl} alt="Promotion" style={{ width: '90%', height: 'auto', marginTop: '10px' }} />
        </Grid>

        <Grid item xs={12} md={8} sx={{ textAlign: 'center' }}>
          <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
            <TextField
              label="Email Cliente"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: 2, width: '80%' }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={processing}
              sx={{
                width: '80%',
                borderRadius: '10px',
                mb: 4,
              }}
            >
             {processing ? 'Procesando...' : 'Sumar a la Promoción!'} 
            </Button>
          </form>
        </Grid>



        {isAdmin && (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}
          >
            <Divider width="100%" sx={{ marginTop: '10px', marginBottom: '20px' }} />
            <PromotionStats statistics={promotion.statistics} pid={pid} />
          </Box>

        )}
      </Grid>
    </Container>
  );
};

export default PromotionDetails;
