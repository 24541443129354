import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify'; // Importing toast for feedback
import api from '../../../utils/api'; // Import your API utility

const PromotionList = ({ promotions }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopyLink = (promotionId) => {
    const promotionLink = `${window.location.origin}/promotions/${promotionId}`;
    navigator.clipboard.writeText(promotionLink).then(() => {
      toast.success('Link copiado al portapapeles'); // Provide user feedback
    }).catch(() => {
      toast.error('Error al copiar el link');
    });
  };

  const handleDeletePromotion = async (promotionId) => {
    //Set alert message to confirm
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta promoción?');
    if (!confirmDelete) return;
    try {
      await api.delete(`/api/promotions/${promotionId}`);
      toast.success('Promoción eliminada correctamente, se refrescará la página');
      //Set wait ffor 2 seconds and reload the page
      setTimeout(() => {
        window.location.reload();
      }, 1 * 1000);
    }
    catch (error) {
      console.error('Error deleting promotion:', error);
      toast.error('Error al eliminar la promoción');
    }
  };

  return (
    <TableContainer component={Paper}>
      {promotions.length === 0 ? (
        <Box sx={{ textAlign: 'center', padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            No hay promociones disponibles, empieza creando una en el botón de abajo.
          </Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  color: 'white',
                  fontWeight: 'bold',
                  width: isSmallScreen ? '50%' : 'auto', // Adjust the width for small screens
                }}
              >
                Título
              </TableCell>
              {!isSmallScreen && (
                <>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Descripción
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {backgroundColor: 'primary.dark',},
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Tipo
                  </TableCell>
    
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Visitas 
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Duración
                  </TableCell>
                </>
              )}
              <TableCell
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                  color: 'white',
                  fontWeight: 'bold',
                  width: isSmallScreen ? '50%' : 'auto', // Adjust the width for small screens
                }}
              >
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions.map((promotion) => (
              <TableRow key={promotion._id}>
                <TableCell component={Link} to={`/promotions/${promotion._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {promotion.title}
                </TableCell>
                {!isSmallScreen && (
                  <>
                    <TableCell>{promotion.description.length > 75 ? `${promotion.description.substring(0, 75)}...` : promotion.description}</TableCell>
                    <TableCell>{promotion.promotionType}</TableCell>
                    <TableCell>{promotion.visitsRequired}</TableCell>
                    <TableCell>{promotion.promotionDuration}</TableCell>
                  </>
                )}
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <IconButton component={Link} to={`/promotions/${promotion._id}`} color="primary">
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleCopyLink(promotion._id)} color="primary">
                      <ShareIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeletePromotion(promotion._id)} color="primary">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default PromotionList;
