import React, { useState } from 'react';
import { TextField, Button, Grid, Container, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let formErrors = { name: false, email: false, message: false };
    let isValid = true;

    if (!formData.name) {
      formErrors.name = true;
      isValid = false;
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = true;
      isValid = false;
    }
    if (!formData.message) {
      formErrors.message = true;
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/contact`, formData);
        toast.success('Mensaje enviado correctamente');
      } catch (error) {
        console.error('Error sending message:', error);
        toast.error('Ha habido un error, vuelva a intentarlo');
      }
    }
  };

  return (
    <Container component="form" onSubmit={handleSubmit} maxWidth="lg" sx={{ mt: 3, mb: 5, textAlign: 'center' }} >
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="primary" >¿Interesado en saber más? Contáctanos</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            label="Nombre"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            error={errors.name}
            helperText={errors.name && "Name is required"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            helperText={errors.email && "Valid email is required"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="message"
            label="Mensaje"
            fullWidth
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            error={errors.message}
            helperText={errors.message && "Message is required"}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Enviar Consulta
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
