import React from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import Logo from '../assets/LogoTSinFondoBlanco.png';

export const Footer = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: 'primary.main', mt: 0, pb: 2 }}>
      <Container maxWidth={false} sx={{ px: 3 }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={Logo} alt="FidelidApp" style={{ maxWidth: '25%' }} />
            <Typography component="h1" variant="h5" sx={{ fontFamily: 'Poppins', color: 'white' }}>
              FidelidApp
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" sx={{ color: 'white' }}>
              &copy; {new Date().getFullYear()} FidelidApp. Todos los derechos reservados.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
