import React from 'react';
import { Box, Grid } from '@mui/material';
import key2background from '../../../assets/fondodellave2.png';
import MobileMockup from '../../../assets/MobileMockup.png';

export const MockupsSection = () => {
    return (
        <Box
            sx={{
                backgroundImage: `url(${key2background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '50vh',
                maxHeight: '66vh',
                py: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={10} md={5}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            
                        }}
                    >
                        <img src={MobileMockup} alt="Mockup 1" style={{ maxWidth: '50%', height: 'auto', maxHeight: '100%' }} />
                    </Box>
                </Grid>
                
            </Grid>
        </Box>
    );
};
